import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BlogContentBody from "../../components/pages/BlogDetails/BlogContentBody"

const whatIsLabourProductivityAndHowDoesItImpactYourBusiness = () => {
  return (
    <Layout>
      <Seo
        title="How Labour Productivity Can Impact Your Business"
        description={`Understand the critical impact of labour productivity on your business. Learn how efficient workforce management can enhance efficiency and profitability.`}
      />

      {/* This slug is matched with blogs in Content */}
      <BlogContentBody slug={`what-is-labour-productivity-and-how-does-it-impact-your-business`} />
    </Layout>
  )
}

export default whatIsLabourProductivityAndHowDoesItImpactYourBusiness
